import React, { useEffect, useState } from 'react';
import "./Home.css";
function Imge() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // ดึงข้อมูลแหล่งที่มา (referrer) ของผู้ชม
    const referrer = document.referrer;
   console.log('x '+referrer);
    // ตรวจสอบว่าผู้ชมมาจาก www.google.com หรือไม่
    if (referrer === "https://www.google.com/") {
      // แสดงรูปภาพชุดที่ 1-5 jpg
      renderImages([1, 2, 3, 4, 5]);
    } else {
      // แสดงรูปภาพชุดที่ 6-10 jpg
      renderImages([6, 7, 8, 9, 10]);
    }
  }, []);

  // ฟังก์ชันแสดงรูปภาพ
  const renderImages = (imageNumbers) => {
    const imageElements = imageNumbers.map((number) => (
      <img
        key={number}
        src={`/image/${number}.jpg`}
        alt={`Image ${number}`}
      />
    ));

    // เพิ่มรูปภาพลงใน state ของคุณ
    setImages(imageElements);
  };

  return (
    <div>
   
      <div className="text-center">
        <div className="imge">
        {"สั่งซื้อได้เลย"}
      {images}
       
      </div>
    </div>
    </div>
  );
}

export default Imge;
